/* App.css */
.menu {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.menu a {
    margin: 0 10px;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
}

.menu a:hover {
    color: #007bff;
}
